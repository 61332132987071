import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Navbar from "../components/Navbar";
import Banner from "../components/Banner";
import Animate from "../components/Hero";
function Home() {
return (
<div className=''>
    <Animate/>
</div>
);
}
 export default Home;