import React from "react";
import Testimonial from "../components/Portfoliopicture";


function Test() {
return (
<React.Fragment>
        <Testimonial/>
</React.Fragment>
);
}
 export default Test;