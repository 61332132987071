import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Navbar from "../components/Navbar";
import Banner from "../components/Banner";
import Testimonial from "../components/Portfoliopicture";
function Contact() {
return (
<div className=''>
    <Navbar/>
    <Banner/>
</div>
);
}
 export default Contact;